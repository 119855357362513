
import { defineComponent, watch, reactive } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'

export default defineComponent({
  components: {
    BaseInput
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  emits: [
    'update:form'
  ],
  setup (props, { emit }) {
    const competitorsMax = 3

    const initialCompetitorsValue = Array.isArray(props.form.competitors)
      ? props.form.competitors
      : []

    const competitors = reactive({
      items: [...initialCompetitorsValue]
    })

    watch(competitors, (competitors) => {
      emit('update:form', {
        competitors: competitors.items.filter((competitor) => competitor !== '')
      })
    })

    return {
      competitorsMax,
      competitors
    }
  }
})
