<template>
  <div class="w-full space-y-6">
    <base-input
      v-for="(competitor, index) in competitorsMax"
      :key="`competitor-${index}`"
      :label="$t('page.settings.competitors.form.competitor.label-dynamic', { order: index + 1 })"
      :name="`competitor-${index}`"
      type="url"
      v-model="competitors.items[index]"
      autocomplete="off"
      left-icon="fas fa-globe"
    />

    <div>
      <p class="text-sm text-gray-500">
        {{ $t('page.settings.competitors.sentence.can_be_empty') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, reactive } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'

export default defineComponent({
  components: {
    BaseInput
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  emits: [
    'update:form'
  ],
  setup (props, { emit }) {
    const competitorsMax = 3

    const initialCompetitorsValue = Array.isArray(props.form.competitors)
      ? props.form.competitors
      : []

    const competitors = reactive({
      items: [...initialCompetitorsValue]
    })

    watch(competitors, (competitors) => {
      emit('update:form', {
        competitors: competitors.items.filter((competitor) => competitor !== '')
      })
    })

    return {
      competitorsMax,
      competitors
    }
  }
})
</script>
